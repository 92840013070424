<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>统计图</el-breadcrumb-item>
        <el-breadcrumb-item>充值列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main cardd">
      <!-- 表格 -->
      <el-table ref="filterTable" :data="tableData" :default-sort="{prop: 'id', order: 'descending'}" style="width: 100%">
        <el-table-column prop="id" label="ID" sortable width="130"></el-table-column>
        <el-table-column prop="username" label="账号"></el-table-column>
        <el-table-column prop="true_name" label="姓名">
          <template slot-scope="scope">
            <el-link type="warning">{{scope.row.true_name}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="order_sn" label="订单号"></el-table-column>
        <el-table-column prop="goods_price" label="充值金额"  width="130">
            <template slot-scope="scope">
                <el-link type="danger">{{scope.row.goods_price}}</el-link>
            </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="充值时间"></el-table-column>
        <el-table-column prop="pay_code" label="充值方式">
            <template slot-scope="scope">
                <el-tag
          :type="scope.row.pay_code === 'alipay' ? 'primary' : scope.row.pay_code === 'appleiap' ? 'warning':'success'"
          disable-transitions>{{scope.row.pay_code== 'alipay' ? '支付宝' : scope.row.pay_code === 'appleiap' ? 'AppStore':'微信'}}</el-tag>
            </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagenation :size="10" :total="count" v-on:getPage="handleCurrentChange"></Pagenation>
      </div>
    </div>
  </div>
</template>
<script>
import Pagenation from "../components/pagenation";
export default {
  data() {
    return {
      tableData: [],
      count:1
    };
  },
  components: {
    Pagenation
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    //   请求接口
    getList(page) {
      this.$axios.post("/order_list", { page: page }).then(res => {
        let data = res.data
        this.tableData = data.reverse();
        this.count = res.count
      });
    },
    // 分页
    handleCurrentChange(val) {
        this.getList(val)
    }
  }
};
</script>
<style lang="scss" scoped>
.cardd{
    width: 95% !important;
    margin: 60px auto;
}
</style>